  .headerStyle{
    color:var(--whiteTertiary);
    font-weight: bold;
  }
.VerticalModalStyling{
 position: fixed;
 top:10px;
 right:20px;
 background-color: var(--whitePrimary);
}
.verticalHeader{
  background-color:var(--bluePrimary) ;
  color:var(--whitePrimary);
  height: 5px;
  justify-content: center;
  display: row;
  justify-content: center;
}
.verticalFooter{
  background-color: var(--bluePrimary);
  color:var(--whitePrimary);
}
.verticalContent{
  height: 280px;
  width: 220px;
}
.modTitle{
  font-size: 16px !important;
  color: var(--bluePrimary);
  font-weight: 600;
}