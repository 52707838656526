.App {
  --Blue-color: #d3d3a2;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.font {
  font-family: Roboto;
}
@font-face {
  font-family: Roboto_bold;
  src: url(../src/utils/Fonts/Roboto-Bold.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Roboto_medium;
  src: url(../src/utils/Fonts/Roboto-Medium.ttf);
  font-weight: bold;
}

@font-face {
  font-family: Roboto_light;
  src: url(../src/utils/Fonts/Roboto-Light.ttf);
  font-weight: bold;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.xs-dropdown {
  height: 1.7rem !important;
}

.flex-grid {
  display: flex;
  align-items: center;
}

.no-wrap {
  flex-wrap: nowrap !important;
}

.subhead {
  font-size: 1rem;
  color: var(--bluePrimary);
  text-decoration: underline;
  font-weight: 500;
  padding: 1rem 0px 0px 1rem;
}

.text-heading h2 {
  margin-top: 0;
}

.legend-text {
  font-size: 0.8rem;
  color: var(--bluePrimary);
}
.fixed-header {
  position: sticky;
  top: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.variable {
  --Blue-color: #d3d3d3;
}

@media screen and (max-width: 425px) {
  .flex-grid {
    flex-wrap: wrap !important;
    display: block !important;
    justify-content: center !important;
    padding-top: 10px !important;
  }
}
@media screen and (max-width: 375px) {
  .flex-grid {
    flex-wrap: wrap !important;
    display: block !important;
    justify-content: center !important;
    padding-top: 10px !important;
  }
}
:root {
  --bluePrimary: #130f3f;
  --blueSecondary: #2d3152;
  --blueTertiary: #2c3051;
  --blueQuaternary: #2d3152cf;
  --blueQuaternary: #9475ff;
  --blueQuinary: #5692ff;
  --blueSenary: #003696;
  --blueSeptenary: #73718e;
  --blueOctonary: #0093ff;
  --pinkPrimary: #fd718d;
  --pinkSecondary: #ff2366;
  --greenPrimary: #57d2e2;  
  --whitePrimary: #ffffff;
  --whiteSecondary: #f3f8ff;
  --whiteTertiary: #cfcaca;
  --whiteQuaternary: #f7f7f7;
  --whiteQuinary: #f3f8ff;
  --whiteSenary: #ebeff5;
  --whiteSeptenary: #fafafa;
  --whiteOctonary: #e7e7eb;
  --blackPrimary: #000;
  --blackSecondary: #80808036;
  --blackTertiary: #bfb9b914;
  --yellowPrimary: #dcb209;
  --yellowSecondary: #fff4e5;
  --grayPrimary: #6d6e6f;
  --graySecondary: #70707040;
  --grayTertiary: #838485;
  --greenPrimary: #12e26c;
  --greenSecondary: #336b2d;
  --greenTertiary: #4bb04e;
  --tableBorder: #eaf0f4;
}
