.btnHolder{
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
}
.uploadBtnHold{
    padding: 5px 14px ;
}

.uploadGrid{
    display: flex;
    justify-content: space-between;
      padding: 20px 10px ;
}
.footerBtn{
    display: flex;
    justify-content: center;
}
.fileBtn{
    width: 40%;
    padding: 12px;

}
.adjustBtn{
    padding-top: 10px;
}
.bulkUpload{
    padding:  20px;
}
.uploadBtnGrid{
    display: flex;
    justify-content: space-between;
    padding: 10px 13px;
}
.input{
        display: none;
}
.uploadImg{
    padding-left: 10px;
}
.uploadLabel{
     cursor: pointer;
}
.uploadBtn{
    border: 1px solid var(--greenTertiary);
    padding: 7px 16px;
    border-radius: 36px;
    color: var(--greenTertiary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 452px;
    cursor: pointer; 
}
.approvalBtn{
    border: 1px solid var(--blueOctonary);
    padding: 7px 16px;
    border-radius: 36px;
    color: var(--blueOctonary);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 452px;
    cursor: pointer; 
}
.downloadLink{
    text-decoration: none;
    color:var(--pinkSecondary);
    cursor: pointer;
}
.downloadBtn{
 padding: 7px 16px;
    border-radius: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 452px;
    cursor: pointer; 
}
.bulkUploadClose{
    color: rebeccapurple;
}