.bar {
  fill: #348fdc;
}

text {
  font-size: 12px;
  /* fill: rgb(192, 192, 192); */
  fill:#130F3F
}

#horizondalParentDiv path {
  stroke: gray;
}

#horizondalParentDiv line {
  stroke: gray;
}

.grid path {
  stroke-width: 0;
}

.grid .tick line {
  stroke: #9faaae;
  stroke-opacity: 0.3;
}