.dataLayout{
    width:98%;
    margin:auto
}
.sliding{
    overflow-x: auto;
}
.layoutFormat{
    /* height: 97% !important; */
    transition: 0.3s;
}

 @media only screen and (min-width:768px) {
  .layoutFormat {
    /* height:84% !important; */
  
  } 
} 
 @media only screen and (min-width: 1440px) {
  .layoutFormat {
    /* height:97% !important; */
  
  } 
} 

  @media only screen and (min-width: 2560px) {
  .layoutFormat {
    /* height:98% !important; */
  }
} 
.btnPadding{
    padding:17px !important
}
.movePage{
    filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}
.change{
    filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}
.uploadImgFile{
    width: 15px;
    height: 15px;
    padding: 0px;
    border: 2px solid rgb(49, 48, 48);
    border-radius: 5px;
}
.uploadFile{
    color: rgb(86, 230, 86);
    padding-left: 3px;
}

.IdaFormat{
overflow-y: auto;
height: 100%;
padding-top: 16px;}

.modal-style{
  display: flex;
}

.modal-table {
  width: 100%;
  border-collapse: collapse;
}

.modal-table th, .modal-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.modal-table th {
  background-color: #f2f2f2;
}


