#parentProgressDiv {
  width: 100%;
  height: 100%;
}
.subMain {
  display: flex;
  align-items: center;
}
.stateNameDiv {
  width: 20%;
  color: #130f3f;
  font-size: 0.875rem;
  padding: 5px;
}
.progressDiv {
  width: 70%;
  display: flex;
  border-left: 1px solid #dbdbdb;
}
.myProgress {
  margin-left: 5px;
  width: 100%;
  background-color: #ddd;
  display: flex;
  /* margin-top:5px; */
}
.myProgress div {
  height: 25px;
  text-align: center;
  line-height: 25px;
  color: white;
}
.districtCount {
  color: #afafaf;
  padding: 3px;
  width: 4%;
  border: 1px solid;
  border-radius: 3px;
  text-align: center;
  margin: 3px;
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
}
.underDA {
  background-color: #f9c80e;
}
.underIDA {
  background-color: #437acd;
}
.districtsClearedTAS1 {
  background-color: #e19034;
}
.districtsClearedTAS2 {
  background-color: #afafaf;
}
.districtsClearedTAS3 {
  background-color: #10cedc;
}
.underPre-TAS {
  background-color: #229d77;
}
.totalEndemicDistricts {
  background-color: #dbdbdb;
}
.subMainData {
  max-height: 35rem;
  overflow-y: auto;
}
.progressBarLegends {
  padding: 10px;
  display: flex;
  /* justify-content: space-evenly; */
  align-items: center;
  /* position: sticky;
  top: 0; */
  background: white;
  /* scroll-behavior: revert; */
  overflow-x: scroll;
}

.progressBarLegendsSub {
  position: sticky;
  top: 2px;
  background: white;
}
.progressLegendBox {
  margin-left: 5px;
  width: 15px;
  height: 15px;
}
.legendLabel {
  margin-left: 5px;
  font-size: 0.75rem;
}
.nodata-img {
  width: 10rem;
  padding-top: 5rem;
}
@media screen and (max-width: 425px) {
  .districtCount {
    width: unset;
  }
}
@media screen and (max-width: 320px) {
  .stateNameDiv {
    font-size: 0.6rem;
  }
}
