.dropdown select {
  font-size: 14px;
  width: 7rem;
  height: 35px;
  color: var(--bluePrimary);
  padding: 0px 10px;
  border: 1px solid var(--blackSecondary) !important;
  border-radius: 5px;
  font-family: Roboto_medium;
}

.dropdown select::after {
  content: '';
  top: 14px;
  right: 10px;
  width: 30px;
  height: 20px;
  border: 6px solid var(--whiteTertiary);
}
.dropdown select:focus-visible {
  outline: none;
}
.dropdown select:hover {
  background: var(--whitePrimary) !important;
}
.selectOption {
  font-size: 14px !important;
}
.options {
  font-size: 14px !important;
}
@media screen and (max-width: 425px) {
  .dropdown select {
    width: unset;
    padding: 0px 3px;
  }
}
@media screen and (max-width: 320px) {
  .dropdown select {
    padding: 0px 3px;
  }
}
