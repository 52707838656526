.edit{
    height: 12px;
    padding-top: 12px;
}
.edit:hover {
   filter: invert(66%) sepia(39%) saturate(918%) hue-rotate(91deg) brightness(98%) contrast(94%) !important;
  }
  .resetBtn{
    width: 76px;
     margin-left: -6px;

   
}
  .value{
      font-size: 14px !important;
  }