.breadCrumbsView {
  display: flex;
  align-items: center;
  padding: 0.7rem 0px;
  box-shadow: 0px 0px 10px var(--whiteTertiary);
  position: sticky;
  top: 0;
  background: var(--whitePrimary);
  z-index: 99;
}
@media screen and (max-width: 898px) {
  .heading {
    text-decoration: none !important;
    padding: 5px 0px;
  }
}
@media screen and (max-width: 425px) {
  .breadCrumbsView {
    position: unset !important;
  }
}
@media screen and (max-width: 375px) {
  .breadCrumbsView {
    position: unset !important;
  }
}

.dataInput {
  color: var(--blueSeptenary) !important;
  /* opacity: 0.6; */
  width: 100%;
  text-align: center;
}
