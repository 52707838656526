.dividerRight{
    border-right: 2px solid var(--whiteTertiary);
        line-height: 4px;
            text-decoration: none !important
}
.headerText{
    display: flex;
    justify-content: center;
}
.title{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.activeNav{
    text-decoration: none;
}
.btnGrids{
    padding:0.5rem 0.5rem;
    min-width:140px
}
.font{
    font-size: 0.9rem !important;
        white-space: nowrap;
}
.font:hover{
    box-shadow: unset !important;
}
  @media screen and (max-width: 1440px) {
    .btnGrids{
        min-width:148px;
    }
}