.dataLayout {
  width: 98% !important;
  margin: auto !important;
  height: 89% !important;
  overflow-y: auto;
}
.noPadding {
  padding-left: 0px !important;
  height: 100% !important;
}
.paddingTop {
  padding-top: 10px !important;
  /* overflow: auto; */
}
.paddingView {
  padding-top: 0px !important;
  /* overflow: auto; */
}
.userText {
  padding: 15px 0px 0px 15px;
}
.field {
  background: var(--whiteSeptenary) !important;
  padding: 10px 24px;
  display: flex;
  justify-content: space-between;
}

.insideBtn {
  padding: 3px 9px !important;
}
.splitPage {
  height: 100% !important;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media only screen and (max-width: 768px) {
  .paddingTop {
    padding-top: 10px !important;
    padding-left: 0px !important;
  }
}
@media only screen and (min-width: 1440px) {
  .dataLayout {
    height: 90% !important;
  }
}

@media only screen and (min-width: 2560px) {
  .dataLayout {
    height: 93% !important;
  }
}
.btnStyle {
  width: 100px;
}
.messageContainer {
  position: absolute;
  top: 0px;
  height: 100%;
  width: 100% !important;
  z-index: 99999999;
}
.footBtn {
  padding: 13px 28px 20px 0px;
  display: flex;
  justify-content: flex-end;
}
.textStyles {
  text-align: start;
}
.textStylesWarning {
  text-align: start;
  font-size: 0.95rem !important;
}
.alert_card_background {
  background-color: #fbe2bf;
  width: 50%;
  padding: 20px;
  border-radius: 10px;
}
.alert_card_background span {
  width: 100%;
  display: inline-block;
}
.alert_card_box {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fbf8f8ab;
}
.userRole_container {
  position: relative;
}
