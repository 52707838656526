.plus{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}
.float{
        padding: 12px !important;
    border-radius: 50% !important;

}
.upload{
    padding-left: 6px;
}
.startIcon{
    padding-right: 6px;
}
.ellipse{
    padding: 6px;
    border-radius: 25px !important;
     font-size:16px !important
}
.normalBtn{
    padding:10px 0px;
}
.defaultClass{
    color:var(--whitePrimary) !important;
}
@media screen and (max-width: 1404px) {
   .ellipse{
    padding: 6px;
    border-radius: 25px !important;
     font-size:12px !important
}
  }
  @media screen and (max-width: 1150px) {
   .ellipse{
    padding: 5px;
    border-radius: 25px !important;
     font-size:11px !important
}
  }
   @media screen and (max-width: 1086px) {
   .ellipse{
    /* padding: 4px; */
    border-radius: 25px !important;
     font-size:9px !important
}
  }
     @media screen and (max-width: 890px) {
   .ellipse{
    /* padding: 4px; */
    border-radius: 25px !important;
     font-size:16px !important
}
  }