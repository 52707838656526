.profileIcon {
  height: 30px;
  width: 30px;
}

.appBarBackgroundColor {
  background-color: var(--bluePrimary);
}
.appBarBackgroundColor>div{
  justify-content: space-between;
}

.endView {
  display: flex;
  justify-content: flex-end;
  padding-right: 10px;
}

.textAlign {
  padding-left: 10px;
}

.dataEndView {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  align-content: center;
}

.inputDataStyles {
  padding: 5px;
  font-size: 10px;
}

.iconStyling {
  width: 100%;
  object-fit: scale-down;
}

.partOne {
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.whiteText {
  padding: 10px;
}

.iconHolder {
  padding: 10px 0px;
}

.mailIcon {
  padding-top: 10px;
  height: 19px;
  width: 22px;
}

.option {
  font-size: 9px !important;
  padding-right: 2px;
}

.text {
  padding: 10px;
}

.openMenu {
  cursor: pointer;
  padding: 10px 0px;
  line-height: 0.25;
}

.noPadding {
  padding: 0px !important;
}

.secondHalf {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  padding-top: 0.5rem;
}

.dashboardContent {
  padding: 10px;
}
 
.version {
  font-size: 11px !important;
  padding: 16px 10px;
}

.openMenuItem {
  display: flex;
}

.rotate.down {
  -ms-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.transform {
  transform: rotate(180deg);
}

.profile {
  background: var(--whiteOctonary) !important;
  padding: 10px !important;
}
.grade {
  width: 100px;
}
.dash-icon {
  padding: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
}

.title-font {
  font-size: 1.3rem !important;
  white-space: nowrap;
}
@media screen and (min-width: 1024px) {
  .dash-icon {
    padding-right: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    width: 1.5rem;
    /* height: 1.5rem; */
  }
  .dash-icon .MuiTypography-root {
    font-size: 1.1625rem !important;
  }
  .partOne {
    display: flex;
    padding-left: 1rem;
  }
  .title-font {
    font-size: 1.1rem !important;
  }
}
@media screen and (max-width: 425px) {
  .header {
    height: 20vh !important;
  }
  .avatarIcon{
    /* display: none !important; */
  }
  .avatarBtn{
    padding-right: 10px !important;
    /* display: none !important; */
  }
  .downArrowGrid {
    width: 10px !important;
    padding-top: 21px !important;
  }
  .partOne {
    justify-content: flex-start !important;
    padding-top: 1rem !important;
    padding-left: 0px;
  }
  .option {
    font-size: 12px !important;
    padding-left: 10px;
  }
  .profileHeader {
    font-size: 0.85rem !important;
    padding-left: 10px;
    padding-top: 7px;
  }
  .grade {
    width: 85px !important;
  }
  /* .title-font {
    font-size: 1.15rem !important;
    white-space: nowrap;
  } */
  .secondHalf {
    justify-content: space-between !important;
  }
}
@media screen and (max-width: 375px) {
  .header {
    height: 20vh !important;
  }
  .avatarIcon {
    /* display: none !important; */
  }
  .avatarBtn {
    padding-right: 10px !important;
    /* display: none !important; */
  }
  .downArrowGrid {
    width: 10px !important;
    padding-top: 21px !important;
  }
  .partOne {
    justify-content: flex-start !important;
    padding-top: 1rem !important;
    padding-left: 0px;
  }
  .option {
    font-size: 12px !important;
    padding-left: 10px;
  }
  .profileHeader {
    font-size: 0.85rem !important;
    padding-left: 10px;
    padding-top: 7px;
  }
  .avatar {
    display: none !important;
  }
  .grade {
    width: 85px !important;
  }
  .secondHalf{
    justify-content: space-between !important;
  }
}

@media screen and (max-width: 320px) {
  .header {
    height: unset !important;
  }
  .downArrowGrid {
    width: 10px !important;
  }
  .partOne {
    justify-content: center !important;
    padding-top: 1rem !important;
    padding-left: 0px;
  }
  .option {
    font-size: 12px !important;
    padding-left: 10px;
  }
  .profileHeader {
    font-size: 0.85rem !important;
    padding-left: 10px;
    padding-top: 7px;
  }
  .avatar {
    display: none !important;
  }
  .grade {
    width: 85px !important;
  }
  .title-font {
    font-size: 1rem !important;
    white-space: nowrap;
  }
  .secondHalf {
    justify-content: space-between !important;
  }
}
@media screen and (max-width: 768px) {
  .header {
    height: unset !important;
  }
  .partOne {
    justify-content: center !important;
    padding-top: 1rem !important;
  }
 
}
