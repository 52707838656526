
.DynamicTable{
overflow-y: scroll;
height: 300px;
}

.DynamicTable::-webkit-scrollbar:horizontal{
  height: 10px !important;
}
.tables {
  width: 100%;
  border-collapse: separate;
  background: var(--tableBorder);
}
.inputTables {
  width: 100%;
  border-collapse: collapse;
}
table tbody tr {
  border-bottom: 1px solid var(--whiteSecondary);
}
.tables tbody tr {
  background-color: var(--whitePrimary);
}
.thead {
  background-color: var(--bluePrimary);
  color: var(--whitePrimary);
  border: none;
  font-size: 10px;
  font-family: 'Roboto_medium';
  white-space: nowrap;
  position: sticky;
  top: 0;
}
.thData {
  padding: 0.5rem;
  font-size: 0.875rem;
  white-space: break-spaces;
}
.tdData {
  padding: 10px 20px;
  color: var(--bluePrimary);
  text-align: center;
  font-size: 0.875rem;
  font-weight: 10px;
  font-family: 'Roboto_medium';
  white-space: nowrap;
}
.tdDataNumber {
  text-align: center;
}
.pagination {
  display: flex;
  justify-content: flex-end;
  height: 75px;
}
/* PreTas Table */
.rowHeader {
  display: grid;
  grid-template-columns: 14.25% 14.25% 14.25% 14.25% 14.25% 14.25% 14.25%;
  width: 100%;
  background: var(--bluePrimary);
  font-size: 12px;
}
.monthTd {
  padding: 10px;
  text-align: center;
}
.rowState {
  width: 100%;
  display: grid;
  grid-template-columns: 14.25% auto;
  border-bottom: 1px solid #f7f7f7;
}
.states {
  display: flex;
  justify-content: center;
  align-items: center;
}
.rowValue {
  width: 100%;
}
.monthBackground {
  padding: 10px 20px;
  border-bottom: 1px solid var(--whiteSecondary);
  color: var(--bluePrimary);
  text-align: center;
  /* font-size: 12px; */
  font-weight: 10px;
  background: #faf3d7;
}
.monthHeader {
  display: grid;
  grid-template-columns: 16.66% 16.66% 16.66% 16.66% 16.66% 16.66%;
  border-left: 1px solid #f7f7f7;
  border-top: 1px solid #f7f7f7;
}
.statusHeader {
  padding: 0.3rem 1rem;
  color: #dcb209;
}
.monthTdLast {
  text-align: center;
  padding: 0.3rem;
  color: #130f3f;
}
.rowHeader .css-n2cphu-MuiTypography-root {
  color: white;
}
/* MDA Coverage Table */
.mdaHeader .thData {
  padding: 0.5rem;
  width: 13.28%;
}
.mdaHeader .lastMdaHeader {
  padding: 10px;
  width: 19%;
}
.mdaData .tdData {
  /* padding: 10px; */
  color: var(--bluePrimary);
  font-size: 0.875rem;
  font-weight: 10px;
  width: 13.28%;
}

.lastMdaData .tdData {
  /* padding: 10px 11px; */
  width: 13.28%;
}
.slidingBtn {
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
}
.slideThData {
  color: var(--yellowPrimary) !important;
  padding: 8px 19px;
  border-collapse: collapse;
  font-family: 'Roboto_medium';
  text-align: center;
  font-size: 0.875rem;
}
.slideThData:nth-last-child(1) {
  text-align: center;
}
.btnWidth {
  width: 120px !important;
}
.btnViews {
  display: flex;
  justify-content: flex-end;
}
.btnHolder {
  padding: 10px;
}
div::-webkit-scrollbar {
  width: 0.6em !important;
  height: 5px !important;
}

div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
}

div::-webkit-scrollbar-thumb {
  background-color: var(--pinkPrimary);
  border-radius: 20px;
}
.dataCellEdit {
  text-align: center;
  padding: 7px 0px;
  border: 1px solid var(--graySecondary);
  width: 100px;
}
.dataCellEdit:focus-visible {
  outline: none;
}
.actionField {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  height: 63px;
}
.fileFormat {
  padding: 5px 0px 0px 11px;
  cursor: pointer;
}
.masterTable {
  padding: 4px 0px 0px 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.iconAction {
  padding-right: 8px;
  cursor: pointer;
  padding-top: 5px;
  width: 20px;
}
/* .tableTitle{
    font-weight: 500 !important;
   padding: 10px 3px;
} */
.iconTd {
  padding: 2px;
}
.eyeView {
  padding-right: 10px;
}
.deleteData {
  padding-left: 10px;
}

.threshold {
  padding-right: 8px;
}
.eyeView:hover {
  filter: invert(62%) sepia(61%) saturate(4244%) hue-rotate(198deg) brightness(100%) contrast(102%) !important;
}
.editData:hover {
  filter: invert(69%) sepia(69%) saturate(2009%) hue-rotate(92deg) brightness(102%) contrast(86%) !important;
}

.deleteData:hover {
  filter: invert(53%) sepia(90%) saturate(1121%) hue-rotate(310deg) brightness(107%) contrast(98%) !important;
}
.optionalIcons {
  display: flex;
  padding: 10px 0px 0px 0px;
  align-items: center;
  justify-content: center;
  /* border-bottom: 1px solid var(--whiteSecondary); */
  padding-bottom: 11px;
  cursor: pointer;
}

.tableIcon {
  filter: invert(80%) sepia(26%) saturate(7183%) hue-rotate(10deg) brightness(102%) contrast(93%);
}

.activity-title {
  align-items: center;
  display: inline-flex;
}
.editGrid {
  padding-top: 4px;
}
.createSpace {
  padding-right: 10px;
}
.on-row-click {
  cursor: pointer;
}
.on-row-click:hover {
  background-color: var(--whiteSenary);
}
.tableGrid{
  position: relative !important;
}
.searchInput {
  /* position: relative; */
}

.search-input {
  padding: 7px 0px !important;
  width: 100px !important;
  text-align: center;
}

.dataCellEditChange {
  /* position: absolute;
  top: 33px;
  left: 18px; */
  width: 100px !important;
  display: block;
  padding: 7px 0px !important;
}