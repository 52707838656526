.pdfView {
    border: 1px solid var(--greenSecondary);
    /* padding: 5px 7px; */
    border-radius: 4px;

}
.progressView{
 width: 98%;
    padding: 2px 0px 0px 10px

}
.progressSet {
   width: 100%;
    padding-right: 10px;
}
.main{
   
    justify-content: space-evenly;
    padding:3px 0px 0px 12px

   
}
.text {
    color: var(--whitePrimary) !important;
      width: 96% !important;
}

.fileName {
    color:var(--blueTertiary)!important;
}
.fileFormatPic{
    padding: 4px 2px 0px 4px;
    width: 22px;
}
.progress{
    padding:5px 20px 0px 10px
}
.flexPosition{
     display: flex;
    flex-direction: row;
    align-items: center;
}
   @media screen and (max-width: 1160px) {
   .fileName{
    font-size: 0.7rem !important;
}
  }
  