.dash-active .icon {
  color: var(--yellowPrimary);
}

.dash-active .dashboardText {
  color: var(--yellowPrimary);
}

.dashboardText {
  white-space: nowrap;
  font-size: 1vw !important;
  padding: 0px 0.5rem;
  text-transform: none;
}

.icon {
  color: var(--grayPrimary);
}

.dash-active .whiteText {
  color: var(--whitePrimary) !important;
}

.whiteText {
  color: var(--graySecondary);
  text-transform: none;
  cursor: pointer;
}

.heading ~ div > div {
  display: inline-flex;
  align-items: center;
}

.menuBtn {
  padding: 0.5rem !important;
}
.userLitItem {
  font-family: Roboto_bold !important;
  /* color: var(--grayTertiary) ; */
  font-size: 14px;
}
.userLitItem:hover {
  background: var(--whitePrimary) !important;
  color: var(--yellowPrimary) !important;
}

.masterMenu {
  display: flex;
  justify-content: space-evenly;
}
.masterMenuItem {
  color: var(--yellowPrimary) !important;
}
@media screen and (max-width: 425px) {
  .whiteText {
    font-size: 0.85rem !important;
    white-space: nowrap !important;
  }
  .dashboardText {
    font-size: 4vw !important;
  }
  .userLitItem {
    font-size: 16px !important;
  }
  .heading ~ div > div {
    display: block !important;
    align-items: center;
  }
}
@media screen and (max-width: 375px) {
  .whiteText {
    font-size: 0.85rem !important;
    white-space: nowrap !important;
  }
  .dashboardText {
    font-size: 4vw !important;
  }
}
@media screen and (min-width: 768px) {
  .whiteText {
    font-size: 0.85rem !important;
    white-space: nowrap !important;
  }
  .dashboardText {
    font-size: 2vw !important;
  }
}
@media screen and (min-width: 1024px) {
  .whiteText {
    font-size: 0.85rem !important;
    white-space: nowrap !important;
  }
  .dashboardText {
    font-size: 1vw !important;
  }
}
@media screen and (min-width: 1440px) {
  .whiteText {
    font-size: 0.85rem !important;
    white-space: nowrap !important;
  }
  .dashboardText {
    font-size: 1vw !important;
  }
}
