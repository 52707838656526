.rootView {
    border: 2px solid var(--blueQuinary);
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 77px;
    padding: 2px;
}

.countStyle {

    color: var(--blueQuinary) !important;
    padding: 2px 5px 0px 5px;
}

.buttonViewDelete {
    font-size: 20px;
    font-weight: bold;
    color: var(--blueQuinary);
    cursor: pointer;

    padding: 0px 6px;
}

.buttonViewAdd {
    font-size: 20px;
    padding-top: 2px;
    font-weight: bold;
    color: var(--blueQuinary);
    cursor: pointer;

    padding: 0px 5px;

}

.buttonViewSeperate {
    font-size: 20px;
    padding-top: 2px;
    font-weight: bold;
    color: #aec8f7;
    cursor: pointer;
    pointer-events: none;

    padding: 0px 6px;
}

.disable {
    pointer-events: none;
    opacity: 0.3;
}

.countText {
    white-space: nowrap !important;
}