.pagination {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  padding-right: 40px;
}

.pagination > * {
  cursor: pointer;
}
.cursor {
  cursor: pointer;
}
.padding {
  padding: 10px;
}
.rows {
  padding-right: 10px;
  font-weight: 600;
  font-size: 0.9rem;
  font-family: Roboto_medium;
}
.pagination .go {
  font-size: 13px !important;
  font-family: Roboto_medium;
}

.pagination button {
  border: none;
  background: var(--whitePrimary);
  color: var(--blueSecondary);
  font-size: 18px;
  font-family: Roboto_bold;
  padding: 4px;
  margin-left: 5px;
}

.pagination .goto_page {
  font-size: 0.9rem !important;
  white-space: nowrap;
  font-family: Roboto_medium;
  width: 100px !important;
}

.arrow_right {
  filter: invert(16%) sepia(53%) saturate(578%) hue-rotate(194deg) brightness(90%) contrast(88%);
}

.arrow {
  border: 1px solid var(--blueSecondary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.arrow_go {
  border: 1px solid var(--bluePrimary);
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.page_box {
  width: 20px !important;
  height: 25px !important;
  background-color: var(--whitePrimary);
  padding: 10px;
}

.page_box input {
  width: 100%;
  border: 1px solid var(--bluePrimary);
  border-radius: 4px;
  padding: 5px 3px;
}

.page_box input:focus-visible {
  outline: none;
}

.active {
  background-color: var(--bluePrimary) !important;
  color: var(--whitePrimary) !important;
  display: inline-flex;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  width: 30px !important;
  height: 30px !important;
}

@media screen and (max-width: 320px) {
  .pagination {
    padding-right: 0px !important;
  }
  .rows {
    padding-right: 0px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .pagination button {
    font-size: 12px;
    margin-left: 0px;
  }
  .page_box {
    width: 15px !important;
    height: 23px !important;
    padding: 8px;
  }
  .pagination .goto_page {
    width: 70px !important;
    font-size: 0.7rem !important;
  }
  .active {
    width: 20px !important;
    height: 20px !important;
  }
  .page_box input {
    padding: 3px 2px;
  }
  .padding {
    padding: 2px;
  }
}
@media screen and (max-width: 375px) {
  .pagination {
    padding-right: 0px !important;
  }
  .rows {
    padding-right: 0px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .pagination button {
    font-size: 12px;
    margin-left: 0px;
  }
  .page_box {
    width: 15px !important;
    height: 23px !important;
    padding: 8px;
  }
  .pagination .goto_page {
    width: 70px !important;
    font-size: 0.7rem !important;
  }
  .active {
    width: 20px !important;
    height: 20px !important;
  }
  .page_box input {
    padding: 3px 2px;
  }
  .padding {
    padding: 2px;
  }
}
@media screen and (max-width: 425px) {
  .pagination {
    padding-right: 0px !important;
  }
  .rows {
    padding-right: 0px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .pagination button {
    font-size: 12px;
    margin-left: 0px;
  }
  .page_box {
    width: 15px !important;
    height: 23px !important;
    padding: 8px;
  }
  .pagination .goto_page {
    width: 70px !important;
    font-size: 0.7rem !important;
  }
  .active {
    width: 20px !important;
    height: 20px !important;
  }
  .page_box input {
    padding: 3px 2px;
  }
  .padding {
    padding: 2px;
  }
}

@media screen and (max-width: 375px) {
  .pagination {
    padding-right: 0px !important;
  }
  .rows {
    padding-right: 0px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .pagination button {
    font-size: 12px;
    margin-left: 0px;
  }
  .page_box {
    width: 15px !important;
    height: 23px !important;
    padding: 8px;
  }
  .pagination .goto_page {
    width: 70px !important;
    font-size: 0.7rem !important;
  }
  .active {
    width: 20px !important;
    height: 20px !important;
  }
  .page_box input {
    padding: 3px 2px;
  }
  .padding {
    padding: 2px;
  }
}

@media screen and (max-width: 425px) {
  .pagination {
    padding-right: 0px !important;
  }
  .rows {
    padding-right: 0px;
    font-size: 0.7rem;
    white-space: nowrap;
  }
  .pagination button {
    font-size: 12px;
    margin-left: 0px;
  }
  .page_box {
    width: 15px !important;
    height: 23px !important;
    padding: 8px;
  }
  .pagination .goto_page {
    width: 70px !important;
    font-size: 0.7rem !important;
  }
  .active {
    width: 20px !important;
    height: 20px !important;
  }
  .page_box input {
    padding: 3px 2px;
  }
  .padding {
    padding: 2px;
  }
}