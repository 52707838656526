.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
}

.container {
  width: 30rem;
  border: 1px solid gray;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  overflow: auto;
  height: 150px;
  border-radius: 5px;
  background: #dbebeb;
}

.subContent {
  text-align: center;
}

.title {
  color: #4768af;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
}

.showNumber {
  color: red;
}

.showRow {
  display: flex;
}

.inputShow {
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.btnView {
  text-align: end;
  margin: 10px;
}

.btnShow {
  background: white;
  border: 1px solid #918888;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.inputBox {
  height: 22px;
  padding: 0px 5px;
}

.errMsg {
  color: red;
  font-size: 12px;
  margin-top: 3px;
}

.eyeIcon {
  font-size: 19px;
  position: relative;
  right: 23px;
  bottom: 2px;
  cursor: pointer;
}

.winner {
  margin-top: 10px;
  color: green;
  font-size: 17px;
  font-weight: 500;
}

.inputSpace {
  height: 40px;
}

.emptyData {
  margin-top: 10px;
  color: red;
  font-size: 17px;
  font-weight: 500;
}

.oom-map__map {
  padding-left: 2rem;
}

.mapContainer {
  position: relative;
}

.legendBox {
  position: absolute;
  bottom: 0px;
  left: -15px;
}

.legendIndividual {
  bottom: 12%;
}
.toolIndividual {
  bottom: 12% !important;
  right: 10% !important;
}

.lengedContent {
  display: flex;
  align-items: center;
  padding-bottom: 3px;
}

.lengedContent div {
  margin: 0px 0.3rem;
}

.showType {
  color: var(--bluePrimary);
  font-size: 0.8rem;
}

.toolContent {
  position: absolute;
  bottom: 0px;
  right: 20%;
  align-items: center;
  display: inline-flex;
  flex-direction: column;
}

.toolBox {
  margin-top: 0.3rem;
  border: 1px solid #9f9ea0;
  width: 100%;
  border-radius: 4px;
  padding: 0.5rem;
  padding-right: 1.8rem;
  /* background:
  linear-gradient(red, transparent),
  linear-gradient(to top left, lime, transparent),
  linear-gradient(to top right, blue, transparent); */
  box-shadow: 2px 2px 2px 1px rgb(0 0 0 / 20%);
  background: white;
  background-blend-mode: screen;
}

.insideContent {
  display: grid;
  grid-template-columns: 75% 5% 20%;
  width: 100%;
  font-size: 0.9vw;
}

.toolDescript {
  color: 'green';
  overflow-wrap: break-word;
}

.toolCount {
  color: #494949;
}

.toolHeader {
  text-align: center;
  color: #130f3f;
  font-weight: 600;
}

.toolDescription {
  color: '#2C992D';
  overflow-wrap: break-word;
}

.toolCounts {
  color: '#2C992D';
}

.top-left {
  position: absolute;
  left: 0;
}

.top-right {
  position: absolute;
  right: 10%;
}

.geo-tansform-TN svg g {
  transform: translate(-306px, -608px) scale(4);
}

.geo-tansform-AN svg g {
  transform: translate(-540px, -522px) scale(3.4);
}

.geo-tansform-AR svg g {
  transform: translate(-583px, -136px) scale(3.5);
}

.geo-tansform-AS svg g {
  transform: translate(-639px, -214px) scale(3.9);
}

.geo-tansform-BH svg g {
  transform: translate(-560px, -274px) scale(4.6);
}

.geo-tansform-CH svg g {
  transform: translate(-767px, -341px) scale(9);
}

.geo-tansform-DN svg g {
  transform: translate(-468px, -841px) scale(8);
}

.geo-tansform-DD svg g {
  transform: translate(-414px, -832px) scale(8);
}

.geo-tansform-DL svg g {
  transform: translate(-681px, -388px) scale(8);
}

.geo-tansform-GA svg g {
  transform: translate(-516px, -1088px) scale(8);
}

.geo-tansform-GJ svg g {
  transform: translate(-123px, -312px) scale(4);
}

.geo-tansform-HR svg g {
  transform: translate(-534px, -291px) scale(7);
}

.geo-tansform-HP svg g {
  transform: translate(-563px, -164px) scale(7);
}

.geo-tansform-JK svg g {
  transform: translate(-344px, -39px) scale(5.5);
}

.geo-tansform-JH svg g {
  transform: translate(-861px, -519px) scale(6.5);
}

.geo-tansform-KL svg g {
  transform: translate(-385px, -871px) scale(5.4);
}

.geo-tansform-KA svg g {
  transform: translate(-192px, -444.5px) scale(3.5);
}

.geo-tansform-LD svg g {
  transform: translate(-238px, -806.7px) scale(5);
}

.geo-tansform-MP svg g {
  transform: translate(-187px, -208px) scale(3);
}

.geo-tansform-MH svg g {
  transform: translate(-194px, -350px) scale(3.5);
}

.geo-tansform-MN svg g {
  transform: translate(-1494px, -598px) scale(8);
}

.geo-tansform-ML svg g {
  transform: translate(-1369px, -556px) scale(8);
}

.geo-tansform-MZ svg g {
  transform: translate(-1439px, -679px) scale(8);
}

.geo-tansform-NL svg g {
  transform: translate(-1522px, -520.9px) scale(8);
}

/* .geo-tansform-CT svg g {
  transform: translate(-1494px, -598px) scale(8);
} */

.geo-tansform-OD svg g {
  transform: translate(-366px, -317.7px) scale(3.4);
}

.geo-tansform-PY svg g {
  transform: translate(-816px, -1277.9px) scale(8);
}

.geo-tansform-PB svg g {
  transform: translate(-594px, -241px) scale(7.9);
}

.geo-tansform-RJ svg g {
  transform: translate(-99px, -131px) scale(3);
}

.geo-tansform-SK svg g {
  transform: translate(-1230px, -441px) scale(8);
}

/* .geo-tansform-TG svg g {
  transform: translate(-306px, -526px) scale(3.9);
} */

.geo-tansform-TR svg g {
  transform: translate(-1388px, -650px) scale(8);
}

.geo-tansform-UP svg g {
  transform: translate(-227px, -123px) scale(3);
}

/* .geo-tansform-UT svg g {
  transform: translate(-227px, -123px) scale(3)
} */

.geo-tansform-WB svg g {
  transform: translate(-545px, -266px) scale(4);
}

.geo-tansform-TS svg g {
  transform: translate(-306.5px, -449.8px) scale(4);
}

.geo-tansform-AP svg g {
  transform: translate(-230.7px, -351px) scale(3);
}
.geo-tansform-CG svg g {
  transform: translate(-254.8px, -248.6px) scale(3);
}
.geo-tansform-LA svg g {
  transform: translate(-119.7px, 46.7px) scale(2.6);
}
.geo-tansform-ME svg g {
  transform: translate(-1378px, -549.5px) scale(8);
}
.geo-tansform-MI svg g {
  transform: translate(-1452.7px, -673.5px) scale(8);
}
.geo-tansform-ME svg g {
  transform: translate(-561px, -208px) scale(6);
}
.geo-tansform-ME svg g {
  transform: translate(-1378px, -549.5px) scale(8);
}
.geo-tansform-ME svg g {
  transform: translate(-1378px, -549.5px) scale(8);
}

@media screen and (max-width: 425px) {
  .oom-map__map {
    padding-left: 5rem;
  }
  .inside-spacing {
    padding: 1rem;
  }
  .lengedContent .MuiTypography-root {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: 320px) {
  .toolHeader {
    font-size: 3vw !important;
  }
  .inside-spacing {
    padding: 1rem;
  }
}
