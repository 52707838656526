/* .masterMenu{
    display: flex !important;
    justify-content: space-evenly;
} */
.menu{
    font-size: 14px !important;
    cursor: pointer;
}
.menu:hover{
    color: var(--yellowPrimary);
}