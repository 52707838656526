.table {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
}

.rowHeader {
  display: grid;
  grid-template-columns: 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14% 7.14%;
  width: 100%;
  color: var(--bluePrimary);
  font-size: 0.75rem;
  background: var(--whiteQuaternary);
}
.monthHeader {
  display: grid;
  grid-template-columns: 7.6% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7% 7.7%;
}
.rowState {
  display: grid;
  grid-template-columns: 7.14% auto;
  border-bottom: 1px solid var(--whiteQuaternary);
}
.monthTd {
  padding: 10px;
  text-align: center;
}
.states {
  display: flex;
  justify-content: center;
  align-items: center;
}
.monthTdLast {
  text-align: center;
}

.monthNumber {
  border-left: 1px solid var(--whiteQuaternary);
  border-bottom: 1px solid var(--whiteQuaternary);
  text-align: center;
  padding: 16px 0px;
}

.monthNumberLast {
  border-right: 1px solid var(--whiteQuaternary);
  border-bottom: 1px solid var(--whiteQuaternary);
  border-left: 1px solid var(--whiteQuaternary);
  text-align: center;
}
.rowValue {
  width: 100%;
}
.aboveTable {
  display: flex;
  justify-content: space-between;
}
.document {
  padding: 18px 12px;
}

@media only screen and (max-width: 1200px) {
  .rowHeader .monthTd h6 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.empty-padding {
  padding: 20px 0px;
}
@media only screen and (max-width: 320px) {
  .rowState {
    grid-template-columns: 31.14% auto;
    /* grid-template-columns: 30.6% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 7.7%; */
  }
  .states .MuiTypography-root {
    font-size: 0.6rem;
  }
  .monthHeader {
    display: grid;
    grid-template-columns: 30.6% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 30.7% 7.7%;
  }
  /* .rowHeader {
    display: grid;
    grid-template-columns: 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14% 30.14%;
    width: 100%;
    color: var(--bluePrimary);
    font-size: 0.75rem;
    background: var(--whiteQuaternary);
  } */
  .monthTd {
    padding: 10px 26px 10px 0px;
  }
  .monthTd .MuiTypography-root {
    font-size: 8px !important;
  }
  .geo-tansform-OD svg g {
    transform: translate(-330px, -299.7px) scale(3.4);
  }
  .activityNum {
    font-size: 3vw !important;
  }
}
@media screen and (max-width: 768px) {
  .monthTd .MuiTypography-root {
    font-size: 8px !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    font-weight: 600 !important;
    width: 19px !important;
  }
}
@media screen and (max-width: 1024px) {
  .monthTd .MuiTypography-root {
    font-size: 9px !important;
  }
}
@media screen and (max-width: 375px) {
  .states {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    word-break: break-all !important;
  }

  .activityNum {
    font-size: 3vw !important;
  }
}
@media screen and (max-width: 425px) {
  .states {
    display: flex;
    align-items: center;
    word-break: break-all !important;
  }

  .monthTd .MuiTypography-root {
    font-size: 8px !important;
  }
}
