.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.border {
  border-right: 1px solid gray;
}
.dividerStyle{
  border-bottom: 1px dashed gray;
  padding-left: 30px;
    padding-top: 20px;
}