.rootContainer{
    background-color:#e6faff ;
    height:100vh;
}
.loginHeaderStyle{
height:100%;
width:100%;
}
.loginContainer{
    border-radius: 5px;
    left:0px;
    right:0px;
    bottom:0px;
    top:0px;
    display: flex;
    justify-content: center;
}

.gridView{
    height:350px;
    width:250px;
    border-radius: 5px;
}
.fieldStyling{
    width:250px !important;
}
.iconStyle{
    height:15px !important;
    width:15px !important;
}
.commonStyle{
    display: flex;
    justify-content: center;
}
.forgetStyles{
    font-size: 10px;
    white-space: nowrap;
}
.onClick{
    cursor: pointer;
}
.backToLogin{
    display:flex;
    justify-content: flex-end;
    cursor: pointer;
}