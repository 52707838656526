.contentGrid {
    display: flex !important;
    flex-direction: row;
    white-space: nowrap;
    align-items: center !important;
    padding-bottom: 0.3rem;
    padding-top: 0.2rem;
}

.contentGrid h6 {
    font-size: 1rem !important;
}

.contentGrid div {
    max-width: fit-content;
}

.contentGrid span {
    white-space: nowrap;
    font-size: 0.7rem !important;
}

.colon {
    display: flex;
    justify-content: flex-end;
    padding: 0px 5px;
}

.card-label {
    display: flex;
    justify-content: flex-end;
}

.stateView{
    border-left: 1px solid white;
    padding-left: 1em;
}

.middle_label{
    border-bottom: 1px solid white;
    padding-bottom: 5px;
}

.title_grid span{
    font-size: 0.7rem;
}

.title_grid h6{
    font-size: 0.7rem;
}
.Disclaimer{
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}