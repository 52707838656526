.category {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.2rem;
    flex-wrap: wrap;
}

.legend {
    padding: 3px;
    width: 14px;
    height: 10px;
    margin: 4px;
    border-radius: 0px;
}

.legendTypo {
    font-size: 0.8rem !important;
    white-space: nowrap;
}