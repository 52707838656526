.btnPadding {
  padding: 17px !important;
}
.movePage {
  filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}
.change {
  filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}

.footerBtn {
  display: flex;
  justify-content: center;
}
.actionBtn {
  width: 50%;
  padding: 10px;
}
.btn {
  width: 130px;
  border-radius: 90px;
  display: flex;
  float: right;
  padding: 20px;
}
