.icon-grid {
    height: 1.5rem;
    width: 1.5rem;
    /* padding: 0px 0.4rem */
}

.icon-grid img {
    width: 100%;
    height: 100%;
}

.outerGrid {
    padding: 0.3rem;
    align-items: center;
}
.edit-icon{
    height: 1.3rem;
    width: 1.3rem;
    padding: 0px 0.4rem;
}
.fullwidth{
    width:auto !important;
}