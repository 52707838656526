.middleView {
  display: flex;
  justify-content: space-evenly;
}
.middleView {
  display: flex;
  justify-content: flex-start;
}

.divider {
  opacity: 0.8;
  width: 100%;
  text-align: center;
  color: var(--blueSeptenary);
}

.dashboard {
  display: flex !important;
  align-items: center !important;
}

.dash-grid {
  display: flex;
  align-items: center;
  padding: 0.7rem 0px;
  box-shadow: 0px 0px 10px var(--whiteTertiary);
  position: sticky;
  top: 0;
  background: var(--whitePrimary);
  z-index: 99;
}

.heading {
  padding: 0px 0.8rem;
}

.list-items {
  width: 90%;
}
@media only screen and (min-width: 768px) {
  .divider {
    font-size: 1.25rem !important;
  }
}

@media only screen and (min-width: 992px) {
  .divider {
    font-size: 1rem !important;
  }
}

@media only screen and (min-width: 1440px) {
  .divider {
    font-size: 1.2rem !important;
  }
}
@media only screen and (max-width: 425px) {
  .list-items {
    width: 100% !important;
  }
  .dash-grid {
    display: block !important;
    position: unset !important;
  }
}
@media only screen and (max-width: 375px) {
  .list-items {
    width: 100% !important;
  }
  .dash-grid {
    display: block !important;
    position: unset !important;
  }
}
@media only screen and (max-width: 768px) {
  .list-items {
    width: 100% !important;
  }
  .dash-grid {
    display: block !important;
  }
}

.nationalImg {
  width: 100%;
}
.fileGerator div {
  display: flex;
  justify-content: flex-end;
}

.body_content {
  height: 80vh;
  overflow: auto;
}

.hyperlink {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}
