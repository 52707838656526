.box {
  padding: 2px;
}
@media only screen and (max-width: 596px) {
  .box {
    width: 100% !important;
  }
}
.menuItem {
  background: var(--whitePrimary) !important;
  font-family: Roboto_medium !important;
}
.menuItem:hover {
  background: var(--whitePrimary) !important;
  color: var(--yellowPrimary) !important;
}
