.mainView{
    padding-bottom:15px;
}
.textStyling{
    font-size: 18px !important;
    text-decoration: underline 3px;
    text-underline-position: under;
    text-underline-offset: 8px;
    bottom:1px;
}
