.recharts-bar-rectangle {
  background-color: red;
}

.xaxis-label {
  text-anchor: middle;
  font-size: 0.8rem;
  font-weight: 600;
  transform: translate(0%, 3%);
}

.yaxis-label {
  text-anchor: middle;
  font-size: 0.8rem;
  font-weight: 600;
}

.recharts div:first-child .recharts-legend-wrapper {
  bottom: 0px !important;
  font-size: 14px !important;
}
.recharts-default-legend ul li{
  font-size: 10px;
}