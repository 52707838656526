.btnPadding{
    padding:17px !important
}
.movePage{
    filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}
.change{
    filter: invert(84%) sepia(98%) saturate(9%) hue-rotate(150deg) brightness(102%) contrast(102%) !important;
}

.fileFormat{
    padding: 5px 0px 0px 11px;
}