.checkboxStyle{
    display:flex;
    justify-content: center;
    padding-top: 24px;
}

.buttonStyle{
    width:150px !important;
    padding: 3px !important;
    }

.tick_icon{
    padding-top: 8px;
}

.check_icon{
    padding-top: 5px;
}

.tablePanel{
    width:100%;
    table-layout: fixed;
    text-align: center;
    padding: 0 1rem;
}
.tablePanel thead th,
.tablePanel tbody tr td
{padding:0.4rem}

.tablePanel thead th:first-child{text-align: left;}
.tablePanel tbody tr td:first-child{text-align: left;}