
.EditGrid{
      display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

}
.paperGrid{
  display: flex;
    justify-content: flex-end;
    padding: 4px 15px;
    width: 72px;
    border:1px solid var(--whiteOctonary);
        border-radius: 5px;
}
.editPencil{
  padding-right:4px;
   filter: invert(80%) sepia(26%) saturate(7183%) hue-rotate(10deg) brightness(102%) contrast(93%) !important;
  width:15px;
}