.radioGrid{
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
}
.reportTableGrid{
    width:100%;
    padding-top:20px
}

@media screen and (max-width: 1440px) {
   .radioGrid{
   /* justify-content: flex-end !important; */
   padding-top: 10px !important;
   
}

  }