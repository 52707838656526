.nationalImg{
    width: 100%;
}

.nationalImgs{
    width: 95%;
}

.fixed-progress-chart{
    /* max-height: 35rem;
    overflow-y: auto; */
    position: relative;
    margin-bottom: 1.5rem !important;
}
.DisclaimerText{
    font-size: 14px;
    color:gray
}