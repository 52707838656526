.content{
    width:80%;
    /* height: 200px; */
    margin:auto;
    padding-bottom: 8px;
    border-bottom: 1px solid var(--whiteQuinary);
}
.contentTitle{
    font-weight: 600 !important;
}
.title{
    font-weight: 600;
}