.GridView {
  display: flex;
  flex-direction: row;
  /* margin-top: 50px; */
  flex-wrap: wrap;
}

.container {
  display: contents;
  padding: 20px;
  flex-wrap: wrap;
  /* min-height: 100%; */
}

.gridWidth {
  width: 20%;
}

.title-national-dash {
  padding: 1rem 1.25rem;
}

.section-bar {
  padding: 0;
}

.inside-spacing {
  padding: 1rem;
}

.fileGerator div {
  display: flex;
  justify-content: flex-end;
}

.Note {
  padding-right: 5px;
  font-size: 14px;
  color: gray;
}
