.header {
    display: flex !important;
    justify-content: space-between !important;
    background-color: var(--whiteQuaternary);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    /* padding: 15px 10px; */

}

.filterGrid {
    display: flex;
    width: 50%;
    justify-content: flex-start;
}

.filter {
    padding: 10px 10px 0px 0px
}

.activity-section {
    padding: 1rem;
}

.tableView {
    padding-top: 0.5rem;
}

.fixed-activity-height{
    max-height: 30rem;
    overflow-y: auto;
    position: relative;
}
