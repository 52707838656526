.root {
  display: flex;
  flex-direction: row;
  padding-right: 20px;
  align-items: baseline;
}
.element {
  margin-top: 1;
}
.btnElement {
  padding-top: 20px;
  padding-bottom: 20px;
}
.elementsContainer {
  display: flex;
  align-items: center;
  text-align: center;
}
.buttonView {
  display: flex;
  justify-content: flex-end;
  /* padding:0 !important; */
}
.endButtonView {
  display: flex;
  position: fixed;
  bottom: 5%;
  justify-content: flex-end;
  right: 30%;
  padding-top: 10px;
}
.buttonWidth {
  width: 5rem !important;
}
.downloadIcons {
  height: 20px;
  width: 20px;
}
.iconView {
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.downloadFormat {
  padding-top: 15px;
  padding-bottom: 15px;
  display: flex;
}
.space {
  padding-top: 10px !important;
  width: 100%;
}
.verticalDividerStyle {
  border: 1px solid red !important;
}
@media only screen and (min-width: 768px) {
  .space {
    width: 100% !important;
  }
}
@media only screen and (min-width: 596px) {
  .space {
    width: 100% !important;
  }
}
.verticalDivider {
  border-right: 1px solid var(--graySecondary) !important;
}
.countRange {
  display: flex !important;
  justify-content: space-around !important;
}
.disabled {
  pointer-events: none;
  opacity: 0.3;
}
.enable {
  opacity: 1;
  background: red;
}
@media only screen and (min-width: 768px) {
  .activityFormat {
    display: flex;
    justify-content: space-between;
  }
}
.MuiInputBase-root.Mui-disabled {
  background: #f7f7f7;
}

.hide {
  display: none !important;
}

.showCrossIcon {
  position: absolute;
  width: 0.5rem;
  top: -15px;
  right: 0;
  filter: invert(16%) sepia(86%) saturate(5673%) hue-rotate(357deg) brightness(107%) contrast(119%);
}

.relative-grid {
  position: relative;
}
.shrink {
  max-width: 30% !important;
}

.fitContent {
  max-width: max-content !important;
}

.nopadding {
  padding-top: 0 !important;
}

.moveRight {
  position: absolute;
  max-width: fit-content !important;
  right: -9.5%;
}
.remove-icon {
  width: 0.5rem;
  height: 0.5rem;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: -20px;
}

.remove-img {
  width: 100%;
  height: auto;
  filter: invert(33%) sepia(100%) saturate(6230%) hue-rotate(331deg) brightness(101%) contrast(101%);
}

.relative {
  position: relative;
}
