.view_search {
  position: relative;
  width: 100%;
  padding-top:4px
}

.view_search div {
  width: auto;
  position: relative;
  min-width: 2rem;
}
.searchDiv{
  display: inline-flex;
    align-items: center
}
.search_input{
  min-width: 14rem;
  display: none !important;
}
.view_search .searchDiv .search_input {
  display: block !important;
}

.view_search div input {
  border-radius: 4px;
  width: 100%;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 7px 8px;
  border: 1px solid var(--whiteOctonary);
}

.searchDiv input::placeholder {
  font-size: 16px;
  color: var(--yellowPrimary);
  padding: 6px;
  font-family: Roboto-Medium !important;
}

.view_search div img {
  position: absolute;
  right: 8px;
  cursor: pointer;
  width: 33px;
}

.searchIcon{
  width: 1.2rem !important;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

.crossIcon{
    height: 0.7rem;
    filter: invert(65%) sepia(51%) saturate(3037%) hue-rotate(170deg) brightness(103%) contrast(101%);
}
