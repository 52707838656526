.grp{
position: relative;
float: left;
left:12px;
}
.typo{
    position: relative;
    float:left;
    left:20px;
}
.formContainer {
    background-color: var(--whitePrimary);
    padding: 20px;
}