.main_content {
  height: 92vh;
  overflow-y: auto;
}
.layout {
  height: 100vh;
}
.header {
  height: 8vh;
}
.main_content::-webkit-scrollbar {
  display: none;
}
@media screen and (max-width: 425px) {
  .main_content {
    height: 80vh !important;
  }
}
@media screen and (max-width: 768px) {
  .header {
    height: 21vh;
  }
  .main_content {
    height: 72vh;
  }
}
@media screen and (max-width: 1440px) {
  .header {
    height: 10vh;
  }
  .main_content {
    height: 90vh;
  }
}
