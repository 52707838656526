.Calender_customize{
    width:unset !important;
}
.Calender_customize .MuiTypography-root{
    background:#e5edf8 !important;
    width:50px !important;
    height:50px !important;
}

.Calender_customize .MuiButtonBase-root{
    width:50px !important;
    height:50px !important;
}

.Calender_customize .MuiPickersDay-root{
    width:50px !important;
    height:50px !important;
}
.Calender_customize .MuiButtonBase-root:hover{
    background:#f5cf36 !important;
}

.Calender_customize .css-1dozdou{
    margin-top:0px !important;
    padding-left: 38px !important;
    padding-right: 20px !important;
} 
.PrivatePickersSlideTransition-root{
    min-height: 210px !important;
}
  @media screen and (max-width: 1400px) {
    .Calender_customize .MuiTypography-root{
       width: 35px !important;
    height: 35px !important;
    }
    .Calender_customize .MuiButtonBase-root{
    width: 35px !important;
    height: 35px !important;
}
.Calender_customize .MuiPickersDay-root{
    width: 35px !important;
    height: 35px !important;
}
.Calender_customize .css-1dozdou{
    margin-top:0px !important;
    padding-left: 35px !important;
    padding-right: 15px !important;
}
    
}

.date_comp{
    width: 100% !important;
}
.date_comp label{
    color: #484850 !important;
}
.date_comp>div fieldset{
    border-color: #484850 !important;
}